// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import './assets/sass/theming';
// Plus imports for other components in your app.

@import "~@ng-select/ng-select/themes/default.theme.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$EHR-primary: mat-palette($mat-indigo);
$EHR-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$EHR-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$EHR-theme: mat-light-theme(
  (
    color: (
      primary: $EHR-primary,
      accent: $EHR-accent,
      warn: $EHR-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($EHR-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// => Material bootstrap/dist/css/bootstrap.css
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "../../../node_modules/highlight.js/styles/googlecode.css";
@import "../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";
// Global fonts
@import "../../../node_modules/socicon/css/socicon.css";
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";
// Header themes
.header-base-light {
  @import "./assets/sass/themes/layout/header/base/light.scss";
}

.header-base-dark {
  @import "./assets/sass/themes/layout/header/base/dark.scss";
}

// Header Menu themes
.header-menu-light {
  @import "./assets/sass/themes/layout/header/menu/light.scss";
}
.header-menu-dark {
  @import "./assets/sass/themes/layout/header/menu/dark.scss";
}

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

.Activecalendar .fc .fc-button {
  background-color: #1bc5bd !important;
  border-color: #2c3e50 !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', "Helvetica Neue", sans-serif;
}

.modal-open {
  overflow: hidden !important;
}

.dynamicTemplate .modal-dialog {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0px;
  .modal-content {
    min-height: 100vh;
  }
}

.xl .modal-dialog {
  min-width: 80vw;
}

.xxl .modal-dialog {
  min-width: 99vw;
}

.localMediaContainer {
  video {
      max-width: 100%;
  }
}
@media (max-width: 700px) {
  .localMediaContainer {
    video {
      max-width: 110%;
    }
  }
}
.remotemediacontainer {
  video {
    max-width: 600px;
    // max-height: 200px;
  }
}
.user-video{
  video{
    width:100%;
    height:84.5vh;
  }
}
@media (max-width: 700px) {
  .remotemediacontainer {
    video {
      max-width: 350px;
      // max-height: 200px;
    }
  }
  }
.smallWindow {
  video {
    max-width: 200px;
    // max-height: 200px;
  }
}

@media print {
  .myDivToPrint {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    border-color: transparent;
  }
}

.fc-scrollgrid-section-header {
  background: white;
}

.fc-day-today {
  background: transparent !important;
  border-top: 1px solid #ddd !important;
  font-weight: bold;
}

.ngx-contextmenu .dropdown-menu {
  background-color: lightgray;
  padding: 8px;
  a {
    color: black;
  }
}

.modal-backdrop{
  z-index:0 !important;
}

//  @import "~@circlon/angular-tree-component/css/angular-tree-component.css";
//  @import '~@odymaui/angular-tree-component/css/angular-tree-component.css';
 @import '../../../node_modules/@odymaui/angular-tree-component/css/angular-tree-component.css'